import http from "../http-common";
const SettingRoutes ={
    getAll : () => {
        return http.get("/setting");
    },
    manage: (data) => {
        return http.post("/setting", data);
    }
}

export {SettingRoutes}