import React, { useState, useEffect } from "react";
import { SettingRoutes } from "../../services/ApiRoutes";
import { Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

const formFields = {
  twitter_url: "Twitter url",
  discord_url: "Discord url",
  wallet_address: "Wallet address",
  promote_price1: "Promote price(header)",
  promote_price2: "Promote price(featured)",
  promote_price3: "Promote price(footer)",
};

const FormInputGroup = (props) => {
  const { label, name, setting, handleInputChange } = props;
  return <Form.Group className="my-1">
    <Form.Label>{label}</Form.Label>
    <Form.Control type={name.indexOf("price") !== -1 ? "number": "text"} value={setting[name]} name={name} onChange={handleInputChange} />
  </Form.Group>
}


export default function ManageSetting() {
  const initialState = {};
  Object.entries(formFields).map(([key]) => {
    initialState[key] = ""
  })
  const [setting, setState] = useState(initialState);

  const fetchRow = () => {
    SettingRoutes.getAll().then(({ data }) => {
      setState(data.setting);
    }).catch(({ response }) => {
      Swal.fire({
        text: response.data.message,
        icon: "error"
      })
    })
  }

  // get row
  useEffect(() => {
    fetchRow()
  }, [])

  const handleInputChange = event => {
    let { name, value } = event.target;
    setState({ ...setting, [name]: value });
  };
  console.log(setting);


  // form validation submit
  const [validationError, setValidationError] = useState({})
  const submitForm = (e) => {
    e.preventDefault();
    const formData = new FormData()
    for (const field in setting) {
      formData.append(field, setting[field]);
    }
    SettingRoutes.manage(formData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message
        })
      }).catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors)
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error"
          })
        }
      })
  }

  return <>
    <div className="row justify-content-center">
      <div className="col-12 col-sm-12 col-md-8">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Settings</h4>
            <hr />
            <div className="form-wrapper">
              {Object.keys(validationError).length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <ul className="mb-0">
                        {
                          Object.entries(validationError).map(([key, value]) => (
                            <li key={key}>{value}</li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <Form onSubmit={submitForm}>
                {Object.entries(formFields).map(([key, value]) => (
                  <FormInputGroup label={value} name={key} setting={setting} handleInputChange={handleInputChange} />
                ))}
                <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                  Save
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}
