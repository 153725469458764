import React from "react";

const Home = () => {

  return (
    <div className="container">
      Welcome to Picassio CMS
    </div>
  );
};

export default Home;
